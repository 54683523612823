.buy-it {
    display: none;

    .modal {
        padding: 14px;
    }

    img {
        width: 100%;
    }

    &.open{
        display: block;
    }
}