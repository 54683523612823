@import "./../../variables.scss";

.product-details-mod {
    position: relative;
    // z-index: -4;
    height: 723px;
    transition: height .3s ease-in-out;

     &.active {
      height: 935px;
     }

    @media(min-width: 768px) {
         height: 1023px;
        &.active {
          height: 1120px;
         }
    }

    @media(min-width: 1024px) {
        height: 858px;
        &.active {
          height: 1070px;
         }
    }

    @media(min-width: 1366px) {
      &.active {
        height: 1100px;
       }
  }

    &__clouds {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        background-image: url(./../../assets/products/juice-fruit-product-l-mobile@3x.png), url(./../../assets/products/juice-fruit-product-r-mobile@3x.png);
        background-repeat: no-repeat;
        background-size: 507px auto, 417px auto;
        background-position: left -128px top -41px, right -87px top 131px;
        width: 100%;
        height: 100%;
        // z-index: -2;

        @media(min-width: 425px) {
            background-size: 506px auto, 513px auto;
            background-position: left -128px top -50px, right -100px top;
        }

        @media(min-width: 768px) {
            background-image: url(./../../assets/products/juice-fruit-product-l-tablet@3x.png), url(./../../assets/products/juice-fruit-product-r-tablet@3x.png);
            background-size: 686.4px auto, 680px auto;
            background-position: left -146px top -62px, right -112px top 73px;

        }

        @media(min-width: 1024px) {
            background-image: url(./../../assets/products/juice-fruit-product-l-desktop@3x.png), url(./../../assets/products/juice-fruit-product-r-desktop@3x.png);
            background-size: 889.8px auto, 869px auto;
            background-position: left -179px top -50px, right -94px top;
        }
        
    }

    &__container {
        position: absolute;
        display: flex;
        width: 352px;
        height: 340px;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 154px;
        text-align: left;

        @media(max-width: 350px) {
            width: 285px;
        }

        @media(min-width: 768px) {
            height: 537px;
            width: 603px;
            left: 124px;
        }

        @media(min-width: 1024px) {
            margin-top: 84px;
            left: 140px;

        }

        @media(min-width: 1366px) {
            left: 0;
            height: 630px;

        }
    }


    &__content {
        position: relative;
        width: 187px;
        margin: 0 24px ;

        @media(max-width: 350px) {
            margin: 0 16px ;
        }

        @media(min-width: 768px) {
            width: 340px;
            height: 340px;
            margin: auto;
        }
    }

    &__container > img {
        height: 100%;
        width: auto;
        margin-top: 0px;
        margin-right: -40px;
        margin-left: -40px;

        @media(min-width: 768PX) {
            height: 84%;
            width: auto;
            margin-top: 83px;
            margin-right: -82px;
            margin-left: -56px;
        }

    }

    .ctas button {
        margin-left: 0;
        cursor: pointer;

        @media(max-width: 767px) {
            width: 80.5px;
            height: 27.5px;
            margin-right: 10px;
        }

        @media(max-width: 350px) {
            width: 60px;
        }

        @media(min-width: 768px) {
            margin-right: 20px;
        }
    }

    .product-details-mod__button-group {
        display: flex;
        margin-top: 25px;

        button {
            padding: 3px 7px;
            border: 2px solid $faded-blue;
            color: $faded-blue;
            font-size: 14px;
            font-weight: 900;
            border-radius: $button-radius;
            background-color: transparent;
            margin: 0 3px;
            cursor: pointer;

            @media(min-width: 768px) {
                padding: 6px 14px;
            }

            &:first-of-type{
                margin-left: 0;
            }

            &.active {
                color: #fff;
                background-color: $faded-blue;
            }

        }
    }

    h3 {
        display: block;
        margin: 0;
        font-family: korolev-compressed, sans-serif;
        color: $rouge;
        font-size: 35px;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;

        &.orange-juice {
            color: #ee6412
        }

        &.apple-juice {
            color:#005c48;
        }

        &.tropical-fruit-punch{
          color: $rouge;
        }

        &.grapefruit-juice{
            color: #ee5755;
        }

        @media(min-width: 768px) {
            font-size: 58px;
        }
    }

    p {
        width: 100%;
        height: 120px;
        margin: 24px 0 0;
        font-size: 12px;
        color: $faded-blue;

        @media(max-width: 350px) {
            font-size: 11px;
        }

        @media(min-width: 768px) {
            font-size: 18px;
        }
    }
}