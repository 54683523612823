@import url("https://use.typekit.net/dsd1lxd.css");


$lemon-yellow: #f4e381;
$lemon-yellow-bright:#f2cd00;
$lemon-yellow-overlay: rgba(244, 227, 129, 0.4);

$lemon-green: #0a8541;
$apple-green: #78be20;

$text-grey: #646469;

$faded-blue: #5281c2;
$sky-blue:#4bb8ea;
$grapefruit-bg:#32b7b3;

//Radius
$button-radius: 3px;
$modal-radius: 8px;

//Shadow
$modal-shadow: 2px 4px 12px 0 rgba(0, 0, 5, 0.15);


//Juice
$rouge: #b70c1f;

//Heights
$footer-height: 326px;