@import "./../../variables.scss";

.nutrition__button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  color: $faded-blue;
  padding: 7px 0;
  padding-right: 14px;
  font-size: inherit;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
  color: $faded-blue;

  @media(max-width: 350px) {
      font-size: 11px;
  }

  @media(min-width: 768px) {
      font-size: 18px;
  }

  div {
    margin: 0 7px;
    transform: rotate(-180deg);
    transition: .4s;
  }
  
  &:hover {
    text-decoration: underline;

    div {
      transform: rotate(-90deg);
    }
  }

  &.active {
    
    div {
      transform: rotate(-90deg);
    }
  }
}

.nutrition__container {
  width: inherit;
  font-size: inherit;
  font-family: inherit;
  background-color: #ffffff;
  // padding: 7px;
  box-sizing: border-box;
  max-height: 0;
  background-color: white;
  overflow: hidden;
  transition: max-height .4s ease-in-out;
  z-index: 10;
  box-shadow: $modal-shadow;

  &.active {
    max-height: 700px;
    // height: fit-content;
  }


  .nutrition__border {

    .nutrition__table {
      border-collapse: collapse;
      width: inherit;

      tr {
        border: 1px solid #000;
        border-bottom: none;

        &:last-of-type{
          border-bottom: 1px solid #000;
        }

        &.divider_bold {
          border-top: 8px solid;
        }

        &.divider_medium {
          border-top: 4px solid;
        }

        &.divider_none {
          border-top: none;
        }

        td {
          font-size: 11px;
          padding: 2px 3px;

          @media(min-width: 768px) {
            padding: 3px 7px;
            font-size: 14px;
          }

          span {
            font-weight: 900;
          }

          &.no-wrap {
            white-space: nowrap;
          }

          &.right {
            text-align: right;
          }

          &.indent {
            text-indent: 6px;

            @media(min-width: 768px) {
              text-indent: 14px;
            }
          }

          &.indent2 {
            text-indent: 9px;

            @media(min-width: 768px) {
              text-indent: 21px;
            }
          }

          &.title {
            padding: 7px;
            font-size: 25px;
            font-weight: 900;
            text-transform: uppercase;
            font-family: korolev-compressed, sans-serif;

            @media(min-width: 768px) {
              font-size: 38px;
            }

            &.orange-juice {
              color: #ee6412
            }
    
            &.apple-juice {
                color:#005c48;
            }
    
            &.tropical-fruit-punch{
              color: $rouge;
            }
    
            &.grapefruit-juice{
                color: #ee5755;
            }
          }

          &.cals {
            font-family: korolev-compressed, sans-serif;
            font-size: 20px;
            font-weight: 900;

            @media(min-width: 768px) {
              font-size: 33px;
            }

            >span {
              text-align: right;
              color: red;
            }

            &.orange-juice {
              color: #ee6412
            }
    
            &.apple-juice {
                color:#005c48;
            }
    
            &.tropical-fruit-punch{
              color: $rouge;
            }
    
            &.grapefruit-juice{
                color: #ee5755;
            }
          }

          &.legend {
            font-size: 8px;

            @media(min-width: 768px) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}