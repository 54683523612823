@import "./../../variables.scss";

.site-swap-mod {
    position: relative;
    width: 100%;
    height: 150px;
    background-image: url(./../../assets/dole-lemonade-products-master-desktop.png), url(./../../assets/modules/r-lemon-tree.png), url(./../../assets/modules/dole-lemonade-bg-tablet.png);
    background-repeat: no-repeat;
    background-size: 217px, 75px, cover;
    background-position: calc(50% + 80px) 50%, right top, center;

    @media(min-width: 768px) {
        background-image: url(./../../assets/dole-lemonade-products-master-desktop@2x.png), url(./../../assets/modules/r-lemon-tree@2x.png), url(./../../assets/modules/dole-lemonade-bg-tablet@2x.png);
        height: 340px;
        background-size: 508px, 172px, cover;
        background-position: right center, right top, center;
    }

    @media(min-width: 1024px) {
        background-image: url(./../../assets/dole-lemonade-products-master-desktop@3x.png), url(./../../assets/modules/r-lemon-tree@3x.png), url(./../../assets/modules/dole-lemonade-bg-tablet@3x.png);
        background-size: 782px, 267px, cover;
        background-position: right center, right top, center;
        height: 483px
    }

    &__text {
        position: absolute;
        margin: 0 auto;
        margin-top: 13px;
        left: -57%;
        right: 0;
        font-style: normal;
        font-family: reklame-script, sans-serif;
        width: 105px;
        box-sizing: border-box;

        @media(min-width: 768px) {
            width: 238px;
            margin-top: 51px;
        }

        @media(min-width: 1024px) {
            margin-top: 130px;
        }

        @media(min-width: 1500px) {
            width: 395px;
            margin-top: 92px;
        }
    }

    h3 {
        color: $lemon-green;
        margin: 0;
        font-size: 0px;
        font-weight: 500;
        line-height: 1.09;

    }

    img {
        width: 100%
    }

    button {
        width: 105.9px;
        height: 21.1px;
        margin: 0 auto;
        margin-top: 45px;
        color: $lemon-green;
        border: none;
        font-size: 8px;
        font-weight: 900;
        background-color: $lemon-yellow-bright;
        text-transform: uppercase;
        cursor: pointer;

        @media(min-width: 768px) {
            width: 174.5px;
            height: 35.2px;
            margin-top: 77px;
            font-size: 12px;
            border-radius: $button-radius;
        }

        @media(min-width: 1024px) {
            width: 225.6px;
            height: 46.3px;
            font-size: 16px;
        }

        // @media(min-width: 1500px) {
        //     // width: 100%;
        //     height: 60px;
        //     font-size: 22px;
        //     margin-top: 31px;
        // }
    }
}