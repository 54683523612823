@import "./../../variables.scss";

.ctas {
    position: absolute;
    width: 100%;
   
    @media(min-width: 768px) {
        bottom: -21px;
    }

    a {
        display: block;
        text-align: left;

        button {
            width: 110.1px;
            height: 35.2px;
            background-color: $faded-blue;
            color: #fff;
            border: none;
            border-radius: $button-radius;
            font-family: 'Lato', sans-serif;
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
            cursor: pointer;
        }
    }


}