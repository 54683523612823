@import "./../../variables.scss";

.footer {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    height: #{$footer-height};
    background-color: $faded-blue;
    color: #fff;
    font-size: 0.825rem;

    &__container {
        display: flex;
        flex-direction: column;

        @media(min-width: 1024px) {
            flex-direction: row;
            width: 520px;
            margin-top: 46px;
        }
    }

    @media(min-width: 1024px) {
        flex-direction: row;
        justify-content: center;
    }

    img {
        margin: 0 auto;
        margin-top: 25px;
        width: 127.1px;
        height: auto;

        @media(min-width: 768px){
            width: 127px;
            height: auto;
        }

        @media(min-width: 1024px){
            width: auto;
            height: 119px;
            margin-top: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        margin-top: 28px;
        padding: 0 23px;

        @media(min-width: 768px) {
            padding: 0 80px;
        }

        @media(min-width: 1024px) {
            padding: 0;
            margin: 0 auto;
            width: 295px;
        }
    }

    li {
        border-top: 1px solid #fff;
        
        
        &:last-of-type{
            border-bottom: 1px solid #fff;
        }
    }

    a {
        display: block;
        color: #fff;
        text-decoration: none;
        text-align: left;
        width: 100%;
        padding: 12px 0;

        @media(min-width: 1024px) {
            padding: 12px 6px;
        }
    }
}