@import "./../../variables.scss";

.age-checker {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    
    &.open{
        display: block;
    }

    .modal {

        img {
            width: 127.1px;
            margin-top: 30px;
        }

        p {
            width: 300px;
            margin: 0 auto;
            text-align: center;
            font-family: Lato;
            font-size: 16px;
            font-weight: bold;
        }

        .button-group {
                position: absolute;
                display: flex;
                justify-content: center;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                bottom: 39px;
            
            button {
                background-color: $faded-blue;
                width: 110.1px;
                height: unset;
                color: white;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;
                border: none;
                border-radius: $button-radius;
                padding: 10px 30px;
                margin: 0 15px;
                cursor: pointer;
            }
        }
    }
    
}   

.blur {
    filter:blur(4px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(4px);
    -webkit-filter:blur(4px);
}