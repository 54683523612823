@import './../../variables.scss';

.carousel-mod {
    position: relative;
    height: 768px;
    background-position: center center;
    background-size: cover;

    .carousel {
        z-index: 2;
        overflow: visible;
        .slide {
            background-color: transparent;
            cursor: pointer;

            &.selected {
                @media(min-width: 768px) {
                    img {
                        transform: rotate(-8deg);
                    }
                }
            }
        }

        img {
            width: auto;
            height: 312.6px;
            margin: 0 auto;
    
            @media(min-width: 768px) {
                width: auto;
                height: 500px;
                margin-left: -420px;
                margin-top: 112px;            
                // float: left;
                transition: transform .5s ease-in-out;
                transition-delay: .2s;
            }
        }

        .control-dots {
            bottom: -370px;
            @media(min-width: 768px) {
                bottom: -35px;
            }


            .dot {
                width: 23px;
                height: 23px;
                background-image: url(./../../assets/carousel/carousel-outlined-circle.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                box-shadow: none;
                opacity: 1;

                &.selected {
                    background-image: url(./../../assets/carousel/carousel-full-circle.png);
                }
            }
        }
    }

    &__lemon {
        width: 158.4px;
        height: auto;
        margin-top: -53px;

        @media(min-width: 768px) {
            width: 252.8px;
            margin-top: -85px;

        }

        @media(min-width: 1024px) {
            width: 235.2px;
            margin-top: -80px;
        }
    }

    #prevProd, #nextProd {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 110.9px;
        height: 312.6px;
        margin-top: 60px;
        

        @media(min-width: 768px) {
            width: auto;
            height: 500px;
            margin-top: 112px;
        }
    }

    #prevProd {
        left: -180px;
        opacity: 1;
        transition: left .5s ease-in-out;
        animation: pick-me 10s infinite;

        &.hide{
            opacity: 0;
            left: -2000px
        }
    }

    #nextProd {
        right: -160px;
        opacity: 1;
        transition: right .5s ease-in-out;
        animation: pick-me-2 10s infinite;

        &.hide{
            opacity: 0;
            right: -2000px
        }
    }

    .carousel.carousel-slider .control-arrow{
        position: absolute;
        display: inline-block;
        border: none;
        width: 40px;
        height: 40px;
        font-size: 0;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 3;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-top: 110px;
        opacity: unset;

        ::before {
            display: none;
        }

        @media(min-width: 768px){
            width: 35px;
            height: 59.6px;
            margin-top: 330px;
        }

        &.control-prev {
            background-image: url(./../../assets/carousel/left-arrow.svg);
            left: -190px;

            @media(min-width: 768px){
                left: -650px;
            }

            &:disabled {
                opacity: .3;
            }
        }

        &.control-next {
            background-image: url(./../../assets/carousel/right-arrow.svg);
            left: 190px;

            @media(min-width: 768px){
                left: 560px;
            }

            &:disabled {
                opacity: .5;
            }
        }

    }
}

.carousel__slide--visible {
    @media(min-width: 768px) {
        img {
            transform: rotate(-8deg);
        }
    }
}

@keyframes pick-me {
    0% {transform: rotate(0deg)}
    5% {transform: rotate(5deg)}
    6% {transform: rotate(-5deg)}
    7% {transform: rotate(2deg)}
    9% {transform: rotate(-2deg)}
    10% {transform: rotate(0deg)}
    100% {transform: rotate(0deg)}
}

@keyframes pick-me-2 {
    0% {transform: rotate(0deg)}
    5% {transform: rotate(-5deg)}
    6% {transform: rotate(5deg)}
    7% {transform: rotate(-2deg)}
    9% {transform: rotate(2deg)}
    10% {transform: rotate(0deg)}
    100% {transform: rotate(0deg)}
}