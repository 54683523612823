@import "./../../variables.scss";

.modal {
    position: fixed;
    height: 336px;
    width: 369px;
    margin: auto;
    margin-top: 300px;
    border-radius: $modal-radius;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    color: $faded-blue;
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.16);
    z-index: 100;

    img {
        width: 127.1px;
        margin-top: 30px;
    }

    p {
        width: 300px;
        margin: 0 auto;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
    }

    button {
        background-image: url(./../../assets/high-level/modal-close.svg);
        float: right;
        background-color: #fff;
        color: $faded-blue;
        border: none;
        font-size: 0;
        width: 15.8px;
        height: 15.8px;
        margin-right: 12px;
        margin-top: 12px;
        cursor: pointer;
    }

    &__bg {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;

    }
}

