.header {
    position: absolute;
    text-align: left;
    width: 100%;
    z-index: 5;

    &.hide { 
        .header__foreground-tree{
            display: none;
        }
    }

    
    img {
        display: inline-block;
        width: 105px;
        margin: 17px 14px;
        cursor: pointer;

        @media(min-width: 768px) {
            width: 215.2px;
            margin: 25px 18px;
        }

        @media(min-width: 1024px) {
            width: 164px;
            height: 109px;
        }
    }

    button {
        float: right;
        width: 36px;
        height: 50px;
        margin-top: 29px;
        margin-right: 23px;
        background-image: url(./../../assets/high-level/juice-hamburger-menu.svg);
        background-size: cover;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        font-size: 0;
        cursor: pointer;

        @media(min-width: 768px) {
            margin-top: 60px;
            margin-right: 73px;

        }
    }
}

@keyframes breeze-left {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(-5deg);}
    50% { transform: rotate(-3deg);}
    75% { transform: rotate(-7deg);}
    100% { transform: rotate(0deg);}
}

@keyframes breeze-right {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(-6deg);}
    50% { transform: rotate(-4deg);}
    75% { transform: rotate(-7deg);}
    100% { transform: rotate(0deg);}
}