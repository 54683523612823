@import './../../variables.scss';

.recipe-page {

    &__image {
        width: 100%;
        height: 100%;
        max-height: 305px;
        overflow: hidden;

        @media(min-width: 1024px){
            display: none;
        }

        img {
            height: auto;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &__time {
        width: 100%;
        margin: 0;
        padding: 6px 16px;
        background-color: $faded-blue;
        color: #fff;
        font-size: 12px;
        text-align: left;

        @media(min-width: 1024px){
            display: none;
        }

    }

    &__container {
        width: 100%;
        min-height: calc(100vh - #{$footer-height});
        padding: 41px 0 31px;
        background-image: url(./../../assets/recipes/component-82-2.png);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .recipe-card {
        position: relative;
        display: flex;
        width: 330px;
        height: 602px;
        padding: 21px 31px;
        margin: 0 auto;
        background-color: $faded-blue;
        border-radius: $modal-radius;
        box-shadow: $modal-shadow;
        text-align: left;
        box-sizing: border-box;

        @media(min-width: 768px) {
            width: 607.5px;
            height: 529px;
        }

        @media(min-width: 1024px) {
            width: 980px;
            height: 600px;
            padding: 61px;
            margin-top: 207px;
        }

        h1, h2 {
            color: #fff;
        }

        h1 {
            margin: 0;
            font-family: korolev-compressed, sans-serif;
            font-size: 35px;
            font-weight: 900;
            line-height: 1.14;

            @media(min-width: 768px) {
                font-size: 50px;
            }
        }

        h2 {
            font-size: 20px;
            font-weight: bold;
        }

        ul, ol {
            padding: 0 17px;
            color: #fff;
            font-size: 12px;
            line-height: 1.67;
        }

        &__content {
            flex-grow: 1;
        }

        &__photo{
            display: none;
            flex-grow: 1; 
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }

            @media(min-width: 1024px) {
                display: block;
                max-width: 438px;
                height: 438px;
                margin-left: 50px;
                overflow: hidden;
            }

            .recipe-card__time {
                width: 100%;
                margin: 0;
                padding: 6px 16px;
                background-color: #fff;
                color: $faded-blue;
            }
        }
    }

    &__lemon {
        position: absolute;
        width: 65%;
        height: auto;
        bottom: -84px;

        @media(min-width: 768px) {
            width: unset;
            right: -69px;
        }
    }

    button {
        margin-top: 90px;
        padding: 9px 43px;
        background-color: $faded-blue;
        color: #fff;
        font-size: 12px;
        font-weight: 900;
        border: 0;
        border-radius: $button-radius;
      
    }
}