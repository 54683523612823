@import "./../../variables.scss";

.carousel-card {
    position: absolute;
    width: 290px;
    height: 520px;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    color: $faded-blue;
    margin-top: 65px;

    @media(min-width: 768px) {
        width: 457px;
        height: 550px;
        margin-top: 0px;
    }

    &__content {
        position: absolute;
        z-index: 2;
        bottom: 65px;
        height: 211px;
        
        @media(min-width: 768px) {
            position: absolute;
            width: 292px;
            height: 275px;
            top: 215px;
            right: 26px;

        }
    }

    .ctas a {
        margin: 0 27px;
    }

    h3 {
        display: block;
        margin: 0;
        padding: 0 27px;
        text-align: left;
        font-family: korolev-compressed, sans-serif;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;

        @media(min-width: 768px) {
            font-size: 34px;
            font-weight: 900;
        }

        img {
            margin-bottom: 40px;
        }
    }



    p {
        padding: 0 27px;
        text-align: left;
        font-size: 14px;

        a {
            color: $faded-blue;
            display: block;
            margin-top: 20px;
            cursor: pointer;
        }
    }


    &__buttons {
        position: absolute;
        width: 100%;

        @media(min-width: 768px) {
            bottom: 0;
        }

        button {
            width: 110.1px;
            height: 35.2px;
            margin: 0 8.5px;
            background-color: $faded-blue;
            color: #fff;
            border: none;
            border-radius: $button-radius;
            font-family: 'Lato', sans-serif;
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
        }
    }

    &__paper{
        position: absolute;
        background-color: #fff;
        height: 360px;
        width: 100%;
        z-index: 1;
        bottom: 0;
        border-radius: $modal-radius;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 5, 0.15);

        @media(min-width: 768px) {
            height: 380px;
        }
    }

    .coming-soon {
        position: absolute;
        background-image: url(./../../assets/carousel/component-126-11.png);
        width: 123px;
        height: 112.3px;
        z-index: 0;
        bottom: 5px;
        transition: bottom .5s ease-in-out;

        @media(min-width: 768px) {
            &.show {
                bottom: -85px;
            }
        }

    }

    &__title {
        z-index: -1;
        width: 100%;
        height: 127.2px;
        border-radius: $modal-radius;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        background-size: 200%;
        background-position: center;

        &.orange-juice {
            background-image: url(./../../assets/carousel/orange-carousel-headline-box.png);
        }

        &.apple-juice {
            background-image: url(./../../assets/carousel/apple-carousel-headline-box.png);
        }

        &.tropical-fruit-punch{
            background-image: url(./../../assets/carousel/tfp-carousel-headline-box.png);
        }
        &.grapefruit-juice {
            background-image: url(./../../assets/carousel/grapefruit-carousel-headline-box.png);
        }
    }

}

.carousel__slide--visible {
    @media(min-width: 768px) {
        .carousel-card img {
            transform: rotate(-8deg);
        }
    }
}

.carousel__slide--hidden {
    left: 20px;
    right: 20px;
}