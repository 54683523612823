@import "./../../variables.scss";

.mixology-mod {
    position: relative;
    height: 431px;
    width: 100%;
    margin-top: -36px;
    background-image: url(./../../assets/modules/group-169.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    box-sizing: border-box;
    overflow: hidden;

    @media(min-width: 768px) {
        height: 701px;
        // background-image: url(./../../assets/mixology-tablet-pattern.png);
    }

    @media(min-width: 1024px) {
        height: 680px;
        // background-image: url(./../../assets/mixology-desktop-pattern@2x.png);
    }

    &__fruits {
        position: absolute;
        background-image: url(./../../assets/modules/group-634.png), url(./../../assets/modules/group-635.png);
        background-repeat: no-repeat;
        background-size: 300px, 300px;
        background-position: left -90px center, right -70px center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        @media(min-width: 768px) {
            background-image: url(./../../assets/modules/group-626.png), url(./../../assets/modules/group-627.png), url(./../../assets/modules/apple-cut.png), url(./../../assets/modules/group-189.png);
            background-size: 350px, 250px, 250px, 250px;
            background-position: left -180px top, calc(50% - 70px) bottom, calc(50% + 130px) center, right -70px center;
        }

    }

    &__modal {
        display: block;
        position: absolute;
        width: 216px;
        height: 163px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 27px;
        padding: 23px 27px;
        background-color: #fff;
        color: $faded-blue;
        border-radius: $modal-radius;
        box-shadow: $modal-shadow;
        text-align: left;
        box-sizing: border-box;

        @media(min-width: 768px) {
            margin-top: 66px;
            width: 277px;
            height: 209px;
        }

        @media(min-width: 1024px) {
            width: 400px;
            height: 320px;
            padding: 41px 51px;
        }

        h3 {
            margin: 0;
            font-family: korolev-compressed, sans-serif;
            font-size: 21px;
            font-weight: 900;

            @media(min-width: 768px) {
                font-size: 30px;
                font-weight: 900;
            }

            @media(min-width: 1024px) {
                font-size: 58px;
                font-weight: 900;
            }
        }

        p {
            font-size: 10px;
            margin-top: 4px;

            @media(min-width: 768px) {
                font-size: 14px;
            }
        }

        button {
            width: 94.8px;
            height: 27.4px;
            background-color: $faded-blue;
            color: #fff;
            border: none;
            border-radius: $button-radius;            
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 900;

            @media(min-width: 768px) {
                font-size: 12px;
                width: 121.5px;
                height: 35.2px;
            }

            @media(min-width: 1024px) {
                margin-top: 25px;
            }
        }
    }

    &__container{
        position: relative;
        width: 100%;
        max-width: 400px;
        height: 100%;
        margin: 0 auto;

        @media(min-width: 768px) {
            max-width: 800px;
        }

        @media(min-width: 1024px) {
            max-width: 1200px;
            
        }
    }

    img {
        position: absolute;
        width: auto;
        height: 330.4px;
        bottom: -109px;

        @media(min-width: 768px) {
            width: auto;
            height: 579.6px;
            bottom: -179px;

        }

        @media(min-width: 1024px) {
            width: auto;
            height: 679.6px;
            bottom: 0;
        }

        &.left {
            left: -7%;

            @media(min-width: 768px){
                left: 0;
            }
        }

        &.right {
            right: -2%;

            @media(min-width: 768px){
                right: 0;
            }
        }


    }
}