@import './../../variables.scss';

.mixology-card {
    position: relative;
    width: 92%;
    max-width: 371px;
    height: 138px;
    margin: 12.5px auto;
    border-radius: $modal-radius;
    box-shadow: $modal-shadow;
    overflow: hidden;
    cursor: pointer;

    @media(min-width: 768px) {
        width: 295px;
        height: 225px;
        margin: 10px 10px;
    }

    &__image {
        width: 157px;
        height: 100%;

         img {
             width: auto;
             height: 100%;
         }
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 218px;
        text-align: left;
        background-color: $faded-blue;
        padding: 13px 36px;
        box-sizing: border-box;

        @media(min-width: 768px) {
            top: unset;
            right: unset;
            bottom: 0;
            width: 100%;
            padding: 8px 18px 18px;
        }

        h3 {
            display: block;
            width: 133px;
            font-family: korolev-compressed, sans-serif;
            color: #fff;
            font-size: 18px;
            font-weight: 900;
            margin: 0;
            line-height: 1.11;

            @media(min-width: 768px) {
                width: 171px;
            }
        }
    
        p {
            font-size: 12px;
            color: #fff;
            margin: 0;
            margin-top: 5.5px;
        }
    }

}