@import "./../../variables.scss";

.mixology-page {
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-color: #fff;

    &__header {
        position: relative;
        height: 293px;
        background-image: url(./../../assets/recipes/mask-group-517.png), url(./../../assets/recipes/droplet-set-orange.png), url(./../../assets/recipes/apple-cut.png), url(./../../assets/high-level/dole-juice-bg.png);
        background-repeat: no-repeat;
        background-size: auto 65% , 230px, 250px, cover;
        background-position: bottom center, left -90px top 80px, right -75px center, top center;
        background-attachment: local, local, local, fixed;

        @media(min-width: 768px) {
            background-image: url(./../../assets/recipes/mask-group-517.png), url(./../../assets/recipes/droplet-set-orange.png), url(./../../assets/recipes/group-608.png), url(./../../assets/high-level/dole-juice-bg.png);
            background-size: auto 65% , 330px, 350px, cover;
            background-position: bottom center, left -140px top 160px, right -100px top 60px, top center;
            height: 459px;
        }

        @media(min-width: 1024px) {
            background-image: url(./../../assets/recipes/mask-group-517.png), url(./../../assets/recipes/group-598.png), url(./../../assets/recipes/group-599.png), url(./../../assets/high-level/dole-juice-bg.png);
            background-size: auto 65% , 570px, 590px, cover;
            background-position: bottom center, left -140px top 160px, right -100px top -70px, top center;
            height: 479px;
        }

        img {
            position: absolute;
            width: 70px;
            left: 0;
            right: 0;
            height: auto;
            margin: 0 auto;

            @media(min-width: 768px) {
                width: 100px;
            }
    
            @media(min-width: 1024px) {
                width: 150px;
            }

        }
        
    }

    &__title {
        position: absolute;
        left: 0;
        right: 0;
        margin: 26px auto 0;

        h2 {
            margin: 0;
            font-family: korolev-compressed, sans-serif;
            font-size: 58px;
            font-weight: 900;
            color: $faded-blue;
            text-transform: uppercase;

            @media(min-width: 768px) {
                font-size: 50px;
            }

            @media(min-width: 1024px) {
                font-size: 65px;
            }
        }
    }

    &__cards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        margin-top: 47px;
        padding-bottom: 47px;


        @media(min-width: 768px) {
            flex-direction: row;
            justify-content: center;
            max-width: 641px;
        }

        @media(min-width: 1024px) {
            flex-direction: row;
            justify-content: start;
            max-width: 956px;
        }

        @media(min-width: 1345px) {
            max-width: 1345px;
            // height: 706px;
        }
    }

    button {
        margin: 46px 0 52px;
        background-color: $faded-blue;
        color:#fff;
        font-size: 12px;
        font-weight: 900;
        padding: 10px 30px;
        border: 0;
        border-radius: $button-radius;

    }

    &__filter {
        background-color: #fff;
        color: $faded-blue;
        box-shadow: $modal-shadow;

        button {
            width: 100%;
            height: 50px;
            border-radius: 0;
            background-color: #fff;
            color: $faded-blue;
            display: block;
            margin: 0;
            text-transform: uppercase;
            text-align: left;
            z-index: -2;
        }

        ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;
            text-transform: capitalize;
            font-size: 18px;
            max-height: 0px;
            overflow: hidden;
            transition: max-height .5s ease-out;
            -moz-transition: max-height .5s ease-out; /* Firefox 4 */
            -webkit-transition: max-height .5s ease-out; /* Safari and Chrome */
            -o-transition: max-height .5s ease-out; /* Opera */
            -ms-transition: max-height .5s ease-out; /* Explorer 10 */

            &.open {
                max-height: 400px;
            }

            li {
                display: block;
                padding: 12px 0;
                width: 100%;
                cursor: pointer;

                button {
                    padding: 0;
                    margin: 0;
                    height: unset;
                    font-size: 18px;
                    font-weight: 300;
                    text-align: center;
                    text-transform: capitalize;
                }

                ul {
                    display: block;
                    padding: 0;
                    margin: 0;
                    margin-top: 26px;
                    text-transform: uppercase;
                    font-size: 12px;
                }
            }
        }
    }
}