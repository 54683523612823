@import "./../../variables.scss";

.hero-mod {
    position: relative;
    height: 606px;
    width: 100%;
    background-image: url(./../../assets/modules/group-583.png), url(./../../assets/modules/group-582.png), url(./../../assets/modules/juice-fruit-l-mobile.png), url(./../../assets/modules/juice-fruit-r-mobile.png);
    background-repeat: no-repeat;
    background-position: top -90px left -1200px, top 5px right -1268px, bottom 160px left -1255px, bottom 190px right -1100px; 
    transition: background-position .7s ease-out;

    &.slide {
       background-position: top -90px left -120px, top 5px right -68px, bottom 160px left -155px, bottom 190px right -110px; 
    }

    @media(min-width: 768px) {
        background-image: url(./../../assets/modules/group-580.png), url(./../../assets/modules/group-579.png), url(./../../assets/modules/group-581.png), url(./../../assets/modules/group-578.png);
        background-size: 330px, 250px, 350px, 400px;
        height: 750px;

        &.slide {
            background-position: top -90px left -120px, top -49px right 60px, bottom left -105px, bottom 100px right -130px;
        }
    }

    @media(min-width: 1024px) {
        background-image: url(./../../assets/modules/group-580.png), url(./../../assets/modules/group-579.png), url(./../../assets/modules/group-581.png), url(./../../assets/modules/group-578.png);
        background-size: 500px, 350px, 520px, 400px;
        background-position: top -160px left -160px, top -65px right 140px, bottom left -125px, bottom 110px right -130px;
        height: 1001px;
        transition: unset;
    }

    &__header {
        position: absolute;
        background-image: url(./../../assets/modules/juice-headline-desktop.svg);
        background-repeat: no-repeat;
        background-size: contain;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 109px;
        width: 238.4px;
        height: 79.8px;

        @media(min-width: 768px) {
            background-image: url(./../../assets/modules/juice-headline-desktop@2x.png);
            width: 405.9px;
            height: 138.5px;
        }

        @media(min-width: 1024px) {
            background-image: url(./../../assets/modules/juice-headline-desktop@3x.png);
            width: 540.8px;
            height: 184.5px;
        }
    }

    img {
        position: absolute;
        width: auto;
        height: 282px;
        left: -38px;
        right: -38px;
        bottom: 0;
        margin: 0 auto;

        @media(min-width: 425px){
            height: 320px;
        }

        @media(min-width: 768px){
            height: 504px;
        }

        @media(min-width: 1024px) {
            height: 671.4px;
        }

    }

}


