@import "./../../variables.scss";

.menu {
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    z-index: 10;
    color: #fff;
    transform: translateX(100%);
    transition: transform .3s ease-out;
    overflow-y: auto;


    @media(min-width: 1024px) {
        display: flex;
        overflow: hidden;
    }

    &.open {
        transform: translateX(0);
    }

    button {
        display: block;
        position: absolute;
        right: 12px;
        margin: 0 auto;
        margin-top: 0px;
        width: 110px;
        padding: 7px;
        text-align: left;
        background-color: transparent;
        background-image: url(./../../assets/high-level/modal-close.png);
        background-repeat: no-repeat;
        background-position: right 7px center;
        border: 0;
        color: transparent;
        font-size: 34px;
        font-family: korolev-compressed, sans-serif;
        font-weight: 900;
        font-style: normal;
        z-index: 10;

        @media(min-width: 768px) {
            color: inherit;
            right: 20px;
            margin: 0;
            margin-top: 10px;
        }

        @media(min-width: 1024px) {
            color: transparent;
 
            margin-top: 7px;
        }
        
    }

    &__nav {
        position: relative;
        height: 726px;
        margin: 22px;
        padding: 0 40px;
        background-image: url(../../assets/menu/menu-bg.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;

        @media(min-width: 768px) {
            height: 850px;
            text-align: left;
            padding: 0 70px;
        }

        @media(min-width: 1024px) {
            height: calc(100% - 40px);
            min-height: 660px;
            width: 379px;
            text-align: center;
        }

        img {
            margin-top: 30.5px;
            width: 100%;
            max-width: 245px;
        }

        > ul {
            list-style: none;
            margin: 0;
            margin-top: 10px;
            padding: 0;

            @media(min-width: 768px){
                max-width: 255px;
            }

            li {
                text-align: left;
                cursor: pointer;

                ul {
                    list-style: none;
                    margin: 0;
                    padding-left: 7px;
                    height: 0px;
                    overflow: hidden;
                    transition: height .5s ease-in-out;

                    li a {
                        font-size: 14px;
                        cursor: pointer;
                    }
                }

                ul.open {
                    height: 60px;
                }
            }

            > li:nth-last-child(2){
                border-top: 2px solid #fff;
                margin-top: 32px;
                padding-top: 32px; 
            }
        }

        a {
            display: block;
            color: inherit;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
            padding: 6.5px 0;
        }
    }

    &__products {
        width: 100%;
        
        @media(min-width: 1024px) {
            height: 100vh;
            overflow: auto;
        }

        .product {
            position: relative;
            height: calc((50% - 33px));
            margin: 22px 0;

            @media(min-width: 768px) {
                height: calc((50vh - 30px));
            }
            
            @media(min-width: 1024px) {
                margin-right: 22px;
            }

            img {
                height: 100%;
            }

            .bg {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                z-index: -1;
            }
        }
    }
    
}